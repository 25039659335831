<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-21 15:27:23
 * @LastEditTime: 2021-03-02 12:42:53
 * @FilePath: \acuconference-web\src\components\dialogs\editConference.vue
-->
<template>
  <v-dialog
    v-model="showEditConferenceDialog"
    max-width="1000"
    :persistent="loading"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>
        {{ getDialogTitle }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :label="$t('label_conference_room') + ' *'"
                  :rules="required.concat(trimSpace)"
                  maxLength="500"
                  v-model.trim="editConference.name"
                  :autofocus="false"
                  required
                ></v-text-field>
                <v-text-field
                  :label="$t('label_input_label_max_participants')+'*'"
                  v-model="editConference.maxParticipants"
                  maxLength="11"
                  type="number"
                  :rules="resultMaxParticipantsNumber"
                   @keydown="KeyboardEvent"
                ></v-text-field>
                <v-select
                  v-if="roomModeList.length > 1"
                  v-model="editConference.type"
                  :rules="required"
                  :items="roomModeList"
                  item-text="label"
                  item-value="id"
                  :label="$t('label_type') + ' *'"
                ></v-select>
                <v-select
                  v-if="conferenceModeList"
                  v-model="editConference.roomInfo.conferenceModes"
                  :items="conferenceModeList"
                  item-text="label"
                  item-value="id"
                  :label="$t('label_conference_mode')"
                ></v-select>
                <v-row>
                  <v-checkbox
                    v-model="editConference.enableRecord"
                    :label="$t('label_input_label_enable_conference_record')"
                  ></v-checkbox>
                  <v-spacer></v-spacer>
                <!-- waiting room -->
                    <v-checkbox
                    v-model="editConference.waitingRoom"
                    :label="$t('label_input_label_enable_waiting_room')"
                  ></v-checkbox>
                  <v-spacer></v-spacer>
                </v-row>
                <v-textarea
                  :label="$t('label_description')"
                  maxLength="2000"
                  rows="1"
                  v-model="editConference.description"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :label="$t('label_normal_account')"
                  maxLength="500"
                  :disabled="true"
                  v-model="editConference.userAccount"
                ></v-text-field>
                <v-text-field
                  :label="$t('label_conference_invitation_code') + ' *'"
                  :rules="inviteCheck"
                   @blur="blurEditCode(editConference.inviteCode)"
                  maxLength="50"
                  v-model.trim="editConference.inviteCode"
                ></v-text-field>
                <!-- 启用高安全性（2FA身份验证） -->
                <!-- <v-switch
                  :label="$t('label_use_heigh_security')"
                  v-model="editConference.highSecurity"
                ></v-switch> -->
                <!-- 访问安全性验证  :row="!$vuetify.breakpoint.mobile"  :column="$vuetify.breakpoint.mobile"-->
                <v-radio-group
                  v-model="editConference.security"
                  :label="$t('label_access_security')"
                >
                  <v-radio :value="3">
                    <template v-slot:label>
                      <div class="label-items">
                        {{ $t("label_use_heigh_security") }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              style="
                                width: 25px;
                                height: 25px;
                                display: inline-block;
                              "
                            >
                              <img
                                src="../../assets/help.svg"
                                style="width: 100%; height: 100%"
                              />
                            </div>
                          </template>
                          <span>{{ $t("label_text_2FA") }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="1">
                    <template v-slot:label>
                      <div class="label-items">
                        {{ $t("label_normal_Domain") }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                           <div
                              v-bind="attrs"
                              v-on="on"
                              style="
                                width: 25px;
                                height: 25px;
                                display: inline-block;
                              "
                            >
                              <img
                                src="../../assets/help.svg"
                                style="width: 100%; height: 100%"
                              />
                            </div>
                          </template>
                          <span>{{ $t("label_text_domain") }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="2">
                    <template v-slot:label>
                      <div class="label-items">
                        {{ $t("label_Access_code") }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                           <div
                              v-bind="attrs"
                              v-on="on"
                              style="
                                width: 25px;
                                height: 25px;
                                display: inline-block;
                              "
                            >
                              <img
                                src="../../assets/help.svg"
                                style="width: 100%; height: 100%"
                              />
                            </div>
                          </template>
                          <span>{{ $t("label_text_access_code") }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="0">
                    <template v-slot:label>
                      <div class="label-items">
                        {{ $t("label_none") }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                           <div
                              v-bind="attrs"
                              v-on="on"
                              style="
                                width: 25px;
                                height: 25px;
                                display: inline-block;
                              "
                            >
                              <img
                                src="../../assets/help.svg"
                                style="width: 100%; height: 100%"
                              />
                            </div>
                          </template>
                          <span>{{ $t("label_text_none") }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <!-- <v-text-field
                  v-if="editConference.security === 1"
                  :value="enterprise.domain"
                  :disabled="true"
                  :label="$t('label_access_domain')"
                >
                </v-text-field> -->
                <v-text-field
                  :rules="required"
                  maxLength="500"
                  v-if="editConference.security === 2"
                  v-model.trim="editConference.roomInfo.accessCode"
                  :label="$t('label_Access_code') + ' *'"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="showEditConferenceDialog = false">
          {{ $t("label_btn_cancel") }}
        </v-btn>
        <v-btn
          text
          color="red"
          @click="confirmDeleteConference"
          v-if="editConference.id"
        >
          {{ $t("label_btn_delete") }}
        </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="!canSubmit || !valid"
          :loading="loading"
          @click="submit"
        >
          {{ $t("label_btn_login_submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apis from "../../mixins/apis";
import regexCheck from "../../mixins/regCheckRulesMixin";
import { mapState } from "vuex";
export default {
  mixins: [apis, regexCheck],
  props: {
    conference: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
    enterprise: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      editStatus: false,
      canSubmit: false,
      valid: false,
      required: [(v) => !!v || this.$t("error_tips_required_params")],
      showEditConferenceDialog: false,
      editConference: {
        roomInfo: {},
        maxParticipants:0
      },
      selectConferenceMode: [2, 1, 3, 4],
    };
  },

  computed: {
    conferenceModeList() {
      if (
        this.editConference.type === 7 ||
        this.editConference.type === undefined
      ) {
        return [
          {
            id: [1, 2, 3, 4],
            label: this.$t("label_select_conference_type_host_control"),
          },
          {
            id: [2, 1, 3, 4],
            label: this.$t("label_select_conference_type_interactive_mode"),
          },
          {
            id: [3, 1, 2, 4],
            label: this.$t("label_select_conference_type_large_conference"),
          },
          {
            id: [4, 2, 1, 3],
            label: this.$t("label_select_conference_type_class_mode"),
          },
        ];
      } else {
        return undefined;
      }
    },
    ...mapState("userInfo", ["currentUserInfo"]),
    roomModeList() {
      const roomTypeMap = {
        7: { id: 7, label: this.$t("label_select_room_type_conference") },
        4: { id: 4, label: this.$t("label_select_room_type_video_call") },
      };
      const modeList = [];
      if (this.enterprise.reserve) {
        if (this.enterprise.reserve.canCreateRoomTypes) {
          this.enterprise.reserve.canCreateRoomTypes.forEach((type) => {
            if (roomTypeMap[type]) {
              modeList.push(roomTypeMap[type]);
            }
          });
        }
      }
      if (modeList.length === 0) {
        modeList.push(roomTypeMap[7]);
      }
      return modeList;
    },

    getDialogTitle() {
      if (this.editConference.id) {
        return this.$t("label_edit_conference_info");
      } else {
        return this.$t("label_add_conference");
      }
    },
  },
  watch: {
    editConference: {
      deep: true,
      handler() {
        this.canSubmit = true;
      },
    },
    value(val) {
      if (this.showEditConferenceDialog !== val) {
        this.showEditConferenceDialog = val;
      }
      if (val) {
        if (this.conference.id) {
          this.editConference = JSON.parse(JSON.stringify(this.conference));
        } else {
          this.editConference = this.initNewConference();
        }
        if (this.editConference.roomInfo.conferenceModes) {
          this.selectConferenceMode = this.editConference.roomInfo.conferenceModes;
        }
        if (this.conference.maxParticipants===0) {
          this.editConference.maxParticipants = 0;
         
        }
      }
      this.$nextTick(() => {
        this.editStatus = this.value;
      });
    },
    showEditConferenceDialog(val) {
      if (val !== this.value) {
        this.$emit("input", val);
      }
      if (!val) {
        this.initNewConference();
      }
    },
  },
  methods: {
   KeyboardEvent(e) {
      
      if (e.keyCode === 190 ||e.keyCode === 189 || e.keyCode === 109 || e.keyCode===69 || e.keyCode===107 || e.keyCode===187 || e.keyCode===110 || e.keyCode===229) {
        e.returnValue = false;
      }
    },
       blurEditCode(val){
      this.editConference.inviteCode = val.replace(/\s+/g,'')
    },
    closeEditDialog() {
      this.$emit("finishEditConference");
      this.$nextTick(() => (this.showEditConferenceDialog = false));
    },
    initNewConference() {
      let now = new Date();
      const times = [
        now.getFullYear(),
        now.getMonth() + 1,
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds(),
      ];
      this.canSubmit = true;
      return {
        name:
          "Room_" + this.currentUserInfo.userInfo.name + "_" + times.join("_"),
        userAccount: this.currentUserInfo.userInfo.account,
        type: 7,
        security: 0,
        enableRecord: false,
        waitingRoom:true,
        maxParticipants: this.currentUserInfo.userInfo.maxParticipants,
        userId: this.currentUserInfo.userInfo.id,
        inviteCode: JSON.stringify(Date.now()).slice(-6),
        description: "",
        roomInfo: {
          conferenceModes: [2, 1, 3, 4],
        },
      };
    },
    handelSecurity() {
      if (this.editConference.security === 3) {
        this.editConference.security = 3;
        this.editConference.highSecurity = true;
      }else{
         this.editConference.highSecurity = false;
      }
      return this.editConference;
    },
    async submit() {
      const postRoom = JSON.parse(JSON.stringify(this.handelSecurity()));
      if (postRoom.security !== 2) {
        delete postRoom.roomInfo.accessCode;
      }
      let result;
      if (this.editConference.id) {
        result = await this.submitEditRoom(postRoom);
      } else {
        result = await this.submitAddRoom(postRoom);
      }
      if (result) {
        this.$toast.success(this.$t("prompt_success"));
        this.$nextTick(() => this.closeEditDialog());
      }
    },

    async confirmDeleteConference() {
      const confirm = await this.$confirm.warn(
        this.$t("label_delete_confirm"),
        {
          title: this.$t("label_warn"),
          buttons: [
            {
              label: this.$t("label_btn_cancel"),
              value: "cancel",
              color: "primary",
            },
            {
              label: this.$t("label_btn_confirm"),
              value: "confirm",
              color: "primary",
            },
          ],
        }
      );
      if (confirm === "confirm") {
        const ids = [this.editConference.id];
        const result = await this.deleteConference({
          ids,
        });
        if (result) {
          this.$toast.success(this.$t("prompt_success"));
          this.$emit("afterDelete");
          this.$nextTick(() => this.closeEditDialog());
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.v-input--radio-group__input
  flex-direction: column !important
  align-items: flex-start
.v-input--radio-group legend.v-label
  font-size: 16px !important
.label-items
  width:355px
  display: flex
  align-items: center
  justify-content: space-between
</style>